import React from "react";
import Link from "gatsby-plugin-transition-link/AniLink";

// 1
// cover bg="#FF8A00"
// 1
// 2
// 2
// 3
// fade
// swipe top="entry"
// swipe top="exit"
// entryOffset={80}
// 3
// 4
// paintDrip color="rebeccapurple"
// 4
// 5
// direction="left"
// duration={3}
// bg="
//   url(https://source.unsplash.com/random)
//   center / cover   /* position / size */
//   no-repeat        /* repeat */
//   fixed            /* attachment */
//   padding-box      /* origin */
//   content-box      /* clip */
//   #FFF            /* color */
// "
// 5

const Intent = ({ children, to, classes = null, background = null}) => (
    <Link      
      fade
      duration={0.8}      
      className={classes || "nav-link"}
      to={to}
      activeStyle={{
        color: "#EE6E00",
      }}
      style={{
        color: classes ? "#FFF" : "#000",
        backgroundImage: background ? `url(${background})` : null, 
      }}
    >
      {children}
    </Link>
  )

export default Intent;